import styled from 'styled-components';

import { Color } from '@lerna-monorepo/common/theme';
import { CaseStudySection } from '@lerna-monorepo/common/components/caseStudySection/caseStudySection.component';

export const CaseStudySectionContainer = styled.div`
  background-color: ${Color.secondary};
  position: relative;
`;

export const LocalCaseStudySection = styled(CaseStudySection)`
  padding-top: 150px;
  padding-bottom: 150px;
`;

export const HalfBox = styled.div`
  width: 50%;
  background-color: ${Color.white};
  height: 45px;
  position: absolute;
  bottom: 0;
  right: 0;
`;
