import React from 'react';

import {
  ButtonIconAdvertisingSection
} from '@lerna-monorepo/common/components/buttonIconAdvertisingSection/buttonIconAdvertisingSection.component';
import {
  DynamicContentComponent
} from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import { BigTextHeader } from '@lerna-monorepo/common/components/bigTextHeader/bigTextHeader.component';
import { CaseStudyProduct } from '@lerna-monorepo/common/components/caseStudyProduct/caseStudyProduct.component';
import { DynamicContent } from '@lerna-monorepo/common/interfaces/dynamicContent.types';
import { renderWhenTrue } from '@lerna-monorepo/common/utils';
import { Color } from '@lerna-monorepo/common/theme';

import { CaseStudyPageData } from './caseStudy.types';
import { CaseStudySectionContainer, HalfBox, LocalCaseStudySection } from './caseStudy.styled';
import { DynamicContentComponentContainer } from '../textPage/textPage.styled';

const CaseStudyPage: React.FC<CaseStudyPageData> = (pageData: CaseStudyPageData) => {
  const { data, caseStudySettings, caseStudyBasePath, currentLanguage, basePaths } = pageData.pageContext;
  const { relatedArticlesHeader, buttonIconAdvertisingSection } = caseStudySettings;
  const { acf, title } = data;
  const {
    caseStudyContent,
    image,
    usedMachine,
    relatedArticles,
  } = acf;

  const renderCaseStudyProduct = () => {
    const data = usedMachine.acf.productHeaderSection;
    const url = data.productImages[0].localFile.url;
    return (
      <CaseStudyProduct 
        title={usedMachine.post_title} 
        subtitle={data.subheader.value} 
        image={url} 
      /> 
    );
  };

  const renderContent = () => caseStudyContent.map((caseStudyContentItem: DynamicContent) => (
    <DynamicContentComponentContainer
      key={`${caseStudyContentItem.header.bigHeader.mainContent}${caseStudyContentItem.header.bigHeader.descriptionContent}`}
    >
      <DynamicContentComponent
        header={caseStudyContentItem.header}
        dynamicContent={caseStudyContentItem.dynamicContent}
        renderCaseStudyProduct={renderCaseStudyProduct}
      />
    </DynamicContentComponentContainer>
  ));

  const renderCaseStudySection = renderWhenTrue(() => (
    <CaseStudySectionContainer>
      <LocalCaseStudySection
        header={relatedArticlesHeader}
        caseStudyBoxes={relatedArticles}
        boxesBaseUrl={caseStudyBasePath}
        currentLanguage={currentLanguage}
        basePaths={basePaths}
      />
      <HalfBox />
    </CaseStudySectionContainer>
  ));

  return (
    <>
      <BigTextHeader content={title} backgroundColor={Color.secondary} />
      {renderContent()}
      {renderCaseStudySection(!!relatedArticles)}
      <ButtonIconAdvertisingSection
        icon={buttonIconAdvertisingSection.icon}
        textArray={buttonIconAdvertisingSection.textArray}
        buttonLabel={buttonIconAdvertisingSection.buttonLabel}
        buttonLink={buttonIconAdvertisingSection.buttonLink}
        backgroundColor={Color.white}
      />
    </>
  );
};

export default CaseStudyPage;
